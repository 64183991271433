import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { BrowserView, MobileView } from 'react-device-detect';
const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const centerStyle = {
  display: 'flex',
  flexDirection: 'column', 
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

function App() {
  return (
    <div style={centerStyle}>
      <BrowserView>
      <img src="https://dwq3yv87q1b43.cloudfront.net/public/static/logo/logov1.png" alt="mytravaly_logo" />
      <div id="loader-container" className='d-flex justify-content-center mt-2'>
        <PulseLoader  
          color={'salmon'}
          loading={true}
          css={override}
          size={30} 
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      </BrowserView>
      <MobileView>
      <img src="https://dwq3yv87q1b43.cloudfront.net/public/static/logo/logov1.png" alt="mytravaly_logo" style={{width:'100px',height:'100px'}}/>
      <div id="loader-container" style={{marginTop:'10px'}}>
        <PulseLoader  
          color={'salmon'}
          loading={true}
          css={override}
          size={20} 
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      </MobileView>
    </div>
  );
}

export default App;
