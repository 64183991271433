import "./Css/global.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ThemeContextProvider from './Context/ThemeContext';
import { useEffect, useState, Suspense, lazy } from "react";
import DataContextProvider from "./Context/DataContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import HomePageSketon from "./Pages/SketonLoading/HomePageSketon";
import 'react-toastify/dist/ReactToastify.css';
const NotFoundPage = lazy(() => import('./Pages/NotFoundPage'));
const Footer = lazy(() => import('./Components/Footer'));
const NavBar = lazy(() => import('./Components/NavBar'));
const HomePage = lazy(() => import('./Pages/HomePage'));
const SearchResults = lazy(() => import("./Pages/SearchResults"));
const IndividualHotel = lazy(() => import("./Pages/IndividualHotel"));
const CheckOut = lazy(() => import("./Pages/CheckOut"));
const CompanyDetails = lazy(() => import("./Pages/CompanyDetails"));
const PaymentStatus = lazy(() => import("./Pages/PaymentStatus"));
const PaymentStatusupi = lazy(() => import("./Pages/upiPayementStatus"));
const TermCondition = lazy(() => import("./Pages/TermCondition"));
const ContactUs = lazy(() => import("./Pages/AboputComapany"));
const MakeReservation = lazy(() => import("./Pages/MakeReservation"));
const BussinessPolicy = lazy(() => import("./Pages/BussinesPolicy"));
const ConnectivityCenter = lazy(() => import("./Pages/ConnectivityCenter"));
const AboutCompany = lazy(() => import("./Pages/AboutCompany"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const GuestBookingPolicy = lazy(() => import("./Pages/GuestBookingPolicy"));
const TrustAndSafety = lazy(() => import("./Pages/TrustAndSafety"));
const CyberSequrity = lazy(() => import("./Pages/CyberSequrity"));
const SignupPage = lazy(() => import('./Pages/Signup'));
const LoginPage = lazy(() => import('./Pages/Login'));
const ForgotPassword = lazy(() => import('./Pages/forgotPassword'));
const LoginOtp = lazy(() => import('./Pages/OtpLogin'));
const UpdateUser = lazy(() => import('./Pages/UserProfile'));
const ChnagePassword = lazy(() => import('./Pages/ChnagePassword'));
const TripsStaus = lazy(() => import('./Pages/TripsDetails'));
const Refer = lazy(() => import('./Pages/Refer'));
const GoogleListingPage = lazy( () => import('./Pages/GoogleListingPage'));
function App() {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => {
      setIsDarkMode(event.matches);
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const body = document.body;
    if (isDarkMode) {
      body.classList.add('darkmode');
    } else {
      body.classList.remove('darkmode');
    }
  }, [isDarkMode]);

  return (
    <ThemeContextProvider>
      <DataContextProvider>
        <BrowserRouter scrollRestoration={false}>
          <MainRouter />
        </BrowserRouter>
      </DataContextProvider>
    </ThemeContextProvider>
  );
}

function MainRouter() {
  const location = useLocation();
  return (
    <div className="App">
      <Suspense fallback={<HomePageSketon />}>
        {window.location.pathname !== "/hotel" && <NavBar />}
        {/* <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="page" timeout={300}> */}
            <div className="page">
              <Routes location={location}>
                <Route path="user/change-password" element={<ChnagePassword />} />
                <Route path="user/forgot-password" element={<ForgotPassword />} />
                <Route path="user/otp-login" element={<LoginOtp />} />
                <Route path="user/login" element={<LoginPage />} />
                <Route path="user/update-userprofile" element={<UpdateUser />} />
                <Route path="user/signup" element={<SignupPage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/cyber-sequrity" element={<CyberSequrity />} />
                <Route path="/trust-and-safety" element={<TrustAndSafety />} />
                <Route path="/guest-booking-policy" element={<GuestBookingPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/TermCondition" element={<TermCondition />} />
                <Route path="/bussiness-policy" element={<BussinessPolicy />} />
                <Route path="/makeReservation" element={<MakeReservation />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/connectivity-center" element={<ConnectivityCenter />} />
                <Route path="/about-company" element={<AboutCompany />} />
                <Route path="/payment-status" element={<PaymentStatus />} />
                <Route path="/search-results" element={<SearchResults />} />
                <Route path="/hotel" element={<IndividualHotel />} />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path="/company-details" element={<CompanyDetails />} />
                <Route path="/payments-status" element={<PaymentStatusupi />} />
                <Route path="/booking-status" element={<TripsStaus />} />
                <Route path="/refer-earn" element={<Refer />} />
                <Route path="/hotel-details/:listingId.html" element={<GoogleListingPage />} />
              </Routes>
            </div>
          {/* </CSSTransition>
        </TransitionGroup> */}
         {window.location.pathname !== "/hotel" && <Footer />}
      </Suspense>
    </div>
  );
}

export default App;
